<template>
  <div
    class="row fade-left-element"
    v-bind:class="{ 'fade-left-element animate__animated animate__fadeInLeft ': isVisible }"
    ref="elementToObserve"
  >
    <div class="col-sm-12 col-md-12">
      <div
        class="slide-title text-white font-bold xl:w-3/4"
      >Unlocking Advanced Healthcare with Innovative EHR System</div>
      <div class="slide-logo w-28 md:w-36 my-10">
        <img src="../../assets/images/fourc-color-logo.png" class="w-100" alt />
      </div>
      <div class="slide-description sm:w-3/6 w-3/4 mt-5 text-white">
        Empower providers with our advanced EHR system for optimized care delivery and improved patient care.
        <div class="slide-left-button text-left pt-5 pb-2">
          <base-button
            :href="'/contact-us'"
            id="GetInTouchone"
            class="btn-primary-white"
            v-bind:state="buttonState"
            aria-expanded="false"
          >Get in Touch</base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../BaseButton";
import ButtonState from "../../lib/button-state";

export default {
  components: {
    BaseButton
  },
  data() {
    return {
      buttonState: ButtonState.Ready,
      isVisible: false
    };
  },

  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.5 // Adjust this threshold value as needed
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      });
    }, options);

    observer.observe(this.$refs.elementToObserve);
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/Home/SlideOne.scss";
</style>
